$(document).ready(function () {

    function GetParameterValues(param) {
        var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for (var i = 0; i < url.length; i++) {
            var urlparam = url[i].split('=');
            if (urlparam[0] == param) {
                return urlparam[1];
            }
        }
    }

    /* LOCK / RELEASE SCROLL */
    function getScrollBarWidth() {
        let el = document.createElement("div");
        el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
        document.body.appendChild(el);
        let width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
    }
    window.scrollBarWidth = getScrollBarWidth();
    document.documentElement.style.setProperty('--scrollbarWidth', window.scrollBarWidth + "px");

    window.lockScroll = function () {
        let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
        document.querySelector('html').classList.add('noscroll');
        document.querySelector('html').style.top = -scrollTop + 'px';
    }
    window.releaseScroll = function () {
        let scrollTop = parseInt(document.querySelector('html').style.top);
        document.querySelector('html').classList.remove('noscroll');
        document.querySelector('html,body').scrollTop = -scrollTop;
    }



    $('.js-open-details').on('click', function () {
        lockScroll();
        $('.js-details').removeClass('active');
        $(this).parent().find('.js-details').addClass('active');
    });

    $('.js-close-details').on('click', function () {
        releaseScroll();
        $(this).closest('.js-details').removeClass('active');
    });

    $('.js-details').on('click', function (event) {
        event.stopPropagation();
        releaseScroll();
        $(this).removeClass('active');
    });

    $('.js-details .details__content').on('click', function (event) {
        event.stopPropagation();
    });

    



/* PANORAMA */

    $('.js-panorama-open-filters-mob').on('click', function () {
        lockScroll();
        $('.js-panorama-content-filters').addClass('active');
    });
    $('.js-panorama-close-filters-mob').on('click', function () {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".js-panorama-content").offset().top
        }, 300);
        releaseScroll();
        $('.js-panorama-content-filters').removeClass('active');
    });

    


    $('.js-panorama-filters-title').on('click', function(){
        if( $(this).hasClass('hidden') ){
            $(this).removeClass('hidden');
            $(this).closest('.js-panorama-filters-category').find('.js-panorama-filters-list').slideDown(200);
        }
        else{
            $(this).addClass('hidden');
            $(this).closest('.js-panorama-filters-category').find('.js-panorama-filters-list').slideUp(200);
        }
    });



    var checkedThemes = [];
    var checkedLevels = [];
    var checkedAcademies = [];

    // Function to update the arrays when a checkbox is clicked
    function updateCheckedValues(value, checked, filter) {
        var filterArray;

        switch (filter) {
            case 'filter-themes':
                filterArray = checkedThemes;
                break;
            case 'filter-levels':
                filterArray = checkedLevels;
                break;
            case 'filter-academies':
                filterArray = checkedAcademies;
                break;
            default:
                return;
        }

        if (checked) {
            // Add the value to the array if it's checked and not already present
            if (filterArray.indexOf(value) === -1) {
                filterArray.push(value);
            }
        } else {
            // Remove the value from the array if it's unchecked and present
            var index = filterArray.indexOf(value);
            if (index !== -1) {
                filterArray.splice(index, 1);
            }
            
            if ( !$('.js-filter-themes:checked').length) { 
                $('.js-filter-themes-reset').prop("checked", true);
            }
            if ( !$('.js-filter-academies:checked').length) {
                $('.js-filter-academies-reset').prop("checked", true);
            }
            if ( !$('.js-filter-levels:checked').length) {
                $('.js-filter-levels-reset').prop("checked", true);
            }

        }
        // Call the function to filter the items based on the checked criteria
        filterItems();
    }

    // Function to filter the items based on the checked criteria
    function filterItems() {
        // Show all items first
        $('.js-panorama-academy-item').show();
        $('.js-panorama-list-item').show();
        

        // Apply Filter 1
        if (checkedThemes.length > 0) {
            $('.js-panorama-list-item').not('[data-themes*="' + checkedThemes.join('"], [data-themes*="') + '"]').hide();
        }

        // Apply Filter 2
        if (checkedLevels.length > 0) {
            $('.js-panorama-list-item').not('[data-levels*="' + checkedLevels.join('"], [data-levels*="') + '"]').hide();
        }

        // Apply Filter 3
        if (checkedAcademies.length > 0) {
            $('.js-panorama-academy-item').not('[data-academy*="' + checkedAcademies.join('"], [data-academy*="') + '"]').hide();
        }
        checkIfEmpty();
    }

    function checkIfEmpty(){
        $('.js-panorama-academy-item').each(function(){
            if ($(this).find('.js-panorama-list-item').length == $(this).find('.js-panorama-list-item:hidden').length ){
                $(this).hide();
            }
        });
    }

    // Attach a click event handler to checkboxes of Filter 1
    $('.js-filter-themes').on('click', function () {
        $('.js-filter-themes-reset').prop("checked", false);
        var value = $(this).val();
        var isChecked = $(this).prop('checked');
        updateCheckedValues(value, isChecked, 'filter-themes');
    });

    // Attach a click event handler to checkboxes of Filter 2
    $('.js-filter-levels').on('click', function () {
        $('.js-filter-levels-reset').prop("checked", false);
        var value = $(this).val();
        var isChecked = $(this).prop('checked');
        updateCheckedValues(value, isChecked, 'filter-levels');
        
    });

    // Attach a click event handler to checkboxes of Filter 3
    $('.js-filter-academies').on('click', function () { 
        $('.js-filter-academies-reset').prop("checked", false);
        var value = $(this).val();
        var isChecked = $(this).prop('checked');
        updateCheckedValues(value, isChecked, 'filter-academies');
        if (isChecked == true){
            $('[data-id=' + value + ']').addClass('is-active');
        }
        else{
            $('[data-id=' + value + ']').removeClass('is-active');
        }
        
    });


    //reset 
    $('.js-filter-themes-reset').on('click', function () {
        if (!$(this).isChecked) {
            $(this).prop("checked", true);
        }
        $('.js-filter-themes').prop("checked", false);
        checkedThemes = [];
        filterItems();
    });
    $('.js-filter-levels-reset').on('click', function () {
        if (!$(this).isChecked) {
            $(this).prop("checked", true);
        }
        $('.js-filter-levels').prop("checked", false);
        checkedLevels = [];
        filterItems();
    });
    $('.js-filter-academies-reset').on('click', function () {
        if (!$(this).isChecked) {
            $(this).prop("checked", true);
        }
        $('.js-filter-academies').prop("checked", false);
        $('.map__image g').removeClass('is-active');
        checkedAcademies = [];
        filterItems();
    });

    $('.js-panorama-filters-reset').on('click', function () {
        $('.js-filter-themes').prop("checked", false);
        $('.js-filter-levels').prop("checked", false);
        $('.js-filter-academies').prop("checked", false);
        $('.js-filter-themes-reset').prop("checked", true);
        $('.js-filter-levels-reset').prop("checked", true);
        $('.js-filter-academies-reset').prop("checked", true);
        checkedThemes = [];
        checkedLevels = [];
        checkedAcademies = [];
        filterItems();
        $('.map__image g').removeClass('is-active');
    });

    filterItems();

    
    

/* FILTERS CARDS */

    var checkedFiltersCards = [];

    if ( GetParameterValues('filter') ){
        var urlFilter = GetParameterValues('filter');
        $('#' + urlFilter).prop("checked", true);
        let color = $('#' + urlFilter).data('color');
        $('#' + urlFilter).next().css("background-color", color);
        updateFiltersCheckedValues(urlFilter, true, 'filter');
    }
    
    // Function to update the arrays when a checkbox is clicked
    function updateFiltersCheckedValues(value, checked, filter) {
        var filterCardsArray;
 
        switch (filter) {
            case 'filter':
                filterCardsArray = checkedFiltersCards;
                break;
            default:
                return;
        }

        if (checked) {
            // Add the value to the array if it's checked and not already present
            if (filterCardsArray.indexOf(value) === -1) {
                filterCardsArray.push(value);
            }
        } else {
            // Remove the value from the array if it's unchecked and present
            var index = filterCardsArray.indexOf(value);
            if (index !== -1) {
                filterCardsArray.splice(index, 1);
            }

        }
        // Call the function to filter the items based on the checked criteria
        filterCardsItems();
    }

    // Function to filter the items based on the checked criteria
    function filterCardsItems() {
        // Show all items first
        $('.js-filteredCard').show();
        // Apply Filter 1
        if (checkedFiltersCards.length > 0) {
            $('.js-filteredCard').not('[data-filters*="' + checkedFiltersCards.join('"], [data-filters*="') + '"]').hide();
        }
    }

    // Attach a click event handler to checkboxes of Filter 1
    $('.js-filter').on('click', function () {
        var value = $(this).val();
        var isChecked = $(this).prop('checked');
        let color = $(this).data('color');
        if (isChecked == true){
            $(this).next().css("background-color", color);
        }
        else{
            $(this).next().css("background-color", 'transparent');
        }
        updateFiltersCheckedValues(value, isChecked, 'filter');
    });

    $('.js-filters-reinit').on('click', function () {
        $('.js-filter').prop("checked", false);
        $('.js-filteredCard').show();
        checkedFiltersCards = [];
    });

    





/* MENU */
    $('.js-open-menu').on('click', function () {
        lockScroll();
        $('.overlay').fadeIn(200);
        $(this).parent().addClass('open');
        $('.js-menu').addClass('open');
    });

    $('.js-close-menu').on('click', function () {
        releaseScroll();
        $('.overlay').fadeOut(200);
        $(this).parent().removeClass('open');
        $('.js-menu').removeClass('open');
    });


/* POPUP */
    $('.js-open-popup-rdv').on('click', function(){
        lockScroll();
        $('.overlay').fadeIn(200);
        $('.js-popup-rdv').addClass('active');
    });
    $('.js-close-popup-rdv').on('click', function () {
        releaseScroll();
        $('.overlay').fadeOut(200);
        $('.js-popup-rdv').removeClass('active');
    });

/* SIMULATEUR HELPER */
/*
    $('.js-helper-button').on('click', function(){
        if ($(this).hasClass('active')){
            $(this).removeClass('active');
            $(this).next().slideUp(200);
        }
        else{
            $(this).addClass('active');
            $(this).next().slideDown(200);
        }
        
    });
    */




/* MAP  
var map = document.querySelector('#map')
if (typeof (map) != 'undefined' && map != null) {
    var paths = map.querySelectorAll('.map__image g')

    // Polyfill du foreach
    if (NodeList.prototype.forEach === undefined) {
        NodeList.prototype.forEach = function (callback) {
            [].forEach.call(this, callback)
        }
    }

    var activeArea = function (id) {
        
        //map.querySelectorAll('.is-active').forEach(function (item) {
        //    item.classList.remove('is-active')
        //})

        if (id !== undefined) {
            //document.querySelectorAll('.list-' + id).forEach(function (city) {
            //    city.classList.add('is-active')
            //})
            if ($('[data-id='+id+']').hasClass('is-active')){
                $('[data-id='+id+']').removeClass('is-active');
                $('#' + id).prop("checked", false);
            }
            else{
                $('[data-id='+id+']').addClass('is-active');
                $('#' + id).prop("checked", true);
            }

            var value = $('#'+id).val();
            var isChecked = $('#' + id).prop('checked');
            updateCheckedValues(value, isChecked, 'filter-academies');

        }
    }

    $('.map__image g').on('click', function(){
        var id = $(this).data('id');
        activeArea(id)
    });
        
    
    //links.forEach(function (link) {
    //    link.addEventListener('mouseenter', function () {
    //        var id = this.id.replace('list-', '')
    //        activeArea(id)
    //    })
    //})
    

    //validselectRegion.addEventListener('click', function () {
    //    var id = selectRegion.value
    //   activeArea(id)
    //});
    
    
    //selectRegion.addEventListener('change', function () {
    //    var id = this.value
    //    activeArea(id)
    //});

} */

});


